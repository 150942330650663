import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  infoMessage,
  successMessage,
  withInputAndCall,
} from "../utils/ErrorHandler/errorHandler";
import QRCode from "qrcode.react";
import LoadingOverlay from "react-loading-overlay";
import icono2 from "../../static/LOGO NEXT NEW.png";
import "./QrFlowIntro.scss";
import { Button2 } from "../utils/Buttons/Buttons";

function QrFlowAuth(props) {
  const api = props.api;
  const url = window.location.href.replace("qrFlowAuth", "auth");
  console.log(url);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const UrlCode = query.get("authCode");
  const [project, setProject] = useState({});

  // const [loading, setLoading] = useState(true);
  const [smsSent, setSmsSent] = useState(false);
  const [showbtn, setShowbtn] = useState(false);

  const msgWait = "Esperando la verificación... ¡No cierres esta ventana!";
  const msgSuccess = "Redirigiendo...";
  const msgNonSuccess = "Ya puedes cerrar esta ventana";
  const [text, setText] = useState("");
  const [hideQr, setHideQr] = useState(false);

  useEffect(() => {
    getProject(UrlCode);
  }, []);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function getProject(code) {
    setLoading(true);
    let pro = await api.getProjectByAuthCode(code);
    if (pro) {
      setProject(pro);
      setHideQr(pro?.extraConfig?.hideQr ?? false);
      waitForRedirection();
      setLoading(false);
    }
  }

  async function sendEmail() {
    let response = await api.sendEmailAuth(UrlCode);
    if (response) {
      alert("Correo enviado");
    }
  }

  async function sendSms() {
    let response = await api.sendSmsAuth(UrlCode);
    if (response) {
      alert("SMS enviado");
    }
  }

  async function waitForRedirection() {
    let waiting = true;
    while (waiting) {
      await sleep(3000);
      let response = await api.getAuthRedirection(UrlCode);
      console.log("response");
      console.log(response);
      if (response) {
        if (response.keepWaiting === false) {
          waiting = false;
          if (response.redirect === true && response.url !== undefined) {
            setText(msgSuccess);
            window.location.href = response.url;
          } else if (response.redirect === false) {
            setText(msgNonSuccess);
            setShowbtn(true);
          }
        }
      }
    }
  }
  if (loading) {
    return (
      <LoadingOverlay active={true} spinner text='Cargando...'>
        <div></div>
      </LoadingOverlay>
    );
  }
  if (hideQr) {
    return (
      <div className='App instruccion-geo' style={{ color: "white" }}>
        <div
          className='row'
          style={{
            paddingLeft: "5%",
            textAlign: "center",
            color: "white",
          }}
        >
          <div className='col'>
            <div className='row center'></div>
          </div>
        </div>
        <div className='row' style={{ padding: "0%" }}>
          <div
            className='col-12 pb-4 pt-6'
            style={{ paddingTop: "5%", textAlign: "center" }}
          >
            <div className='row'>
              <div
                className='col-lg-12 col-12'
                style={{ paddingRight: "10%", paddingLeft: "10%" }}
              >
                <h5>
                  Envia el link de autenticación al correo o al celular del
                  usuario usando alguno de estos botones
                  <br />
                </h5>
                <h5>
                  En caso de salirte del proceso desde tu celular, solo debes
                  abrir de nuevo el link enviado
                  <br />
                </h5>
              </div>
              <br />

              <div className='col-lg-12 col-12 pb-5 pt-1'>
                <div style={{ margin: "10px" }}>
                  <Button2
                    text='Enviar por correo'
                    onClick={sendEmail}
                    width='300px'
                    height='50px'
                  />
                </div>
                <div style={{ margin: "10px" }}>
                  <Button2
                    style={{ margin: "10px" }}
                    text='Enviar por SMS'
                    onClick={sendSms}
                    width='300px'
                    height='50px'
                  />
                </div>
              </div>

              <div
                className='col-lg-12 col-12'
                style={{ paddingRight: "10%", paddingLeft: "10%" }}
              >
                <h5>{text}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='App instruccion-geo' style={{ color: "black" }}>
      <div
        className='row'
        style={{
          paddingLeft: "5%",
          textAlign: "center",
          // color: "white",
        }}
      >
        <div className='col'>
          <div className='row center'></div>
        </div>
      </div>
      <div className='row' style={{ padding: "0%" }}>
        <div
          className='col-12 pb-4 pt-6'
          style={{ paddingTop: "5%", textAlign: "center" }}
        >
          <div className='row'>
            <div
              className='col-lg-12 col-12'
              style={{ paddingRight: "10%", paddingLeft: "10%" }}
            >
              <h5>
                Escanea este código para realizar la autenticación desde tu
                celular
                <br />
              </h5>
              <h5>
                En caso de salirte del proceso desde tu celular, solo debes
                escanear de nuevo el código
                <br />
              </h5>
            </div>
            <br />

            <div className='col-lg-12 col-12 pb-5 pt-1'>
              <QRCode
                value={url}
                style={{ marginTop: "1%" }}
                size={258}
                bgColor={"#FFFFFF"}
                fgColor={"#1d1e3b"}
                includeMargin={true}
              />
            </div>

            <div
              className='col-lg-12 col-12'
              style={{ paddingRight: "10%", paddingLeft: "10%" }}
            >
              <h5>{text}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrFlowAuth;
